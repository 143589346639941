<template>
  <div>
    <span @click="handleClick" style="color:#1890FF;font-size:14px;margin-right:20px;cursor: pointer">全部</span>
    <el-date-picker v-model="value" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="handleChange">
    </el-date-picker>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: ""
    }
  },
  methods: {
    handleClick() {
      this.value = ['', '']
      this.$emit('dateChange', this.value)
    },
    handleChange(value) {
      this.value = value
      this.$emit('dateChange', value)
    }
  }
}
</script>

<style>
</style>